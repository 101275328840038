/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import Head from '../shared/Head'

function SEO({ description, lang, meta, title, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `,
  );
  
  const metaDescription =
    description || site.siteMetadata.description;

  const url = site.siteMetadata.siteUrl;
  const ogImage = 'https://www.automuovifix.fi/static/auto-icon-200x200-c632dffc4da8746079a114247eedc25d.png';

  return (
    
    
      
    
    <Helmet
    

      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
     
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: `https://www.automuovifix.fi`
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `https://www.automuovifix.fi/static/auto-icon-200x200-c632dffc4da8746079a114247eedc25d.png`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:image:src`,
          content: `https://www.automuovifix.fi/static/auto-icon-98x148-e55aeeb30e19ad96f701c721cd1998bc.png`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat('<link property="og:image:secure_url" href="https://www.automuovifix.fi/static/auto-icon-200x200-c632dffc4da8746079a114247eedc25d.png>;').concat(meta)}
    />
     
  );
}

SEO.defaultProps = {
  lang: `fi`,
  meta: [],
  description: ``,
 
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
 
};

export default SEO;
